import React from "react";
import { trpc } from "@arena-active/trpc-client";

interface ResponsePlayerProps {
  videoObjectKey?: string;
  audioObjectKey?: string;
}

export const ResponsePlayer: React.FC<ResponsePlayerProps> = ({
  videoObjectKey,
  audioObjectKey,
}) => {
  const {
    data: signedUrl,
    isLoading,
    isError,
  } = trpc.storage.getSignedUrl.useQuery(
    {
      objectKey: videoObjectKey || "",
    },
    {
      enabled: !!videoObjectKey,
    },
  );

  const {
    data: signedAudioUrl,
    isLoading: isAudioLoading,
    isError: isAudioError,
  } = trpc.storage.getSignedUrl.useQuery(
    {
      objectKey: audioObjectKey ?? "",
    },
    {
      enabled: !!audioObjectKey,
    },
  );

  console.log({ signedUrl, signedAudioUrl });
  if (!videoObjectKey && !audioObjectKey) {
    console.error("No objectKey or audioObjectKey provided to ResponsePlayer");
    return <div>No audio/video available</div>;
  }

  if ((videoObjectKey && isLoading) || (audioObjectKey && isAudioLoading)) {
    return <div>Loading...</div>;
  }

  if (
    isError ||
    isAudioError ||
    (videoObjectKey && !signedUrl) ||
    (audioObjectKey && !signedAudioUrl)
  ) {
    return <div>Unable to load video.</div>;
  }

  return (
    <div>
      <video
        controls
        src={signedUrl}
        style={{ width: "100%", maxHeight: videoObjectKey ? 500 : 100 }}
      >
        <source src={signedAudioUrl} />
        <source src={signedUrl} />
      </video>
    </div>
  );
};
