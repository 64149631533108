import React, { useEffect, useState } from "react";
import styles from "./Submission.module.css";
import {
  CompleteUploadTarget,
  ResponsePlayer,
  UploadTarget,
} from "@arena-active/client-lib";
import { trpc } from "@arena-active/trpc-client";

interface SubmissionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedResponse: any;
}

export const Submission: React.FC<SubmissionProps> = ({ selectedResponse }) => {
  const { client } = trpc.useUtils();

  const [uploads, setUploads] = useState<UploadTarget[]>([]);

  useEffect(() => {
    client.activity.getStoredFiles
      .query({
        activityId: selectedResponse?.activity?.id,
        stateId: selectedResponse?.id,
      })
      .then((files) => {
        setUploads(
          files.map((f) => new CompleteUploadTarget(f.file.name, f.file.size)),
        );
      });
  }, [selectedResponse?.activity]);

  if (selectedResponse?.activity?.activityType === "UPLOAD") {
    const getSignedUrl = (
      activityId: number,
      stateId: number,
      filename: string,
    ) =>
      client.activity.getSignedDownloadUrl.query({
        activityId,
        stateId,
        filename,
      });

    const download = async (filename: string) => {
      const url = await getSignedUrl(
        selectedResponse?.activity?.id,
        selectedResponse?.id,
        filename,
      );
      fetch(url)
        // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
        .then((resp) =>
          resp.status === 200
            ? resp.blob()
            : Promise.reject("something went wrong"),
        )
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => console.warn("error downloading file: ", filename));
    };

    return (
      <div className={styles.container}>
        <p>Uploaded files:</p>
        {uploads?.map((upload) => (
          <div
            key={upload.filename}
            className={styles.file}
            onClick={() => download(upload.filename)}
          >
            {upload.filename}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      <div
        className={styles.container}
        dangerouslySetInnerHTML={{
          __html: selectedResponse?.response?.responseText,
        }}
      />
      {(selectedResponse?.response?.videoStorageKey ||
        selectedResponse?.response?.audioStorageKey) && (
        <ResponsePlayer
          audioObjectKey={selectedResponse?.response?.audioStorageKey}
          videoObjectKey={selectedResponse?.response?.videoStorageKey}
        />
      )}
    </>
  );
};
